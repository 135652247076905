pre.description {
  background-color: var(--color-apricot);
  padding: 1em;
  line-height: 1.5em;
  text-overflow: wrap;

  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  font-family: inherit;
  font-size: inherit;
}