.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    align-self: flex-start;
    font-size: 1.5em;
    justify-self: flex-start;
    color: var(--color-deep-purple);
  }

  hr {
    border: 1.5px solid var(--color-deep-purple);
    margin-bottom: 1.5em;
    width: 100%;
  }
}