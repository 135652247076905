.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4vw;

  .navLinks {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
@media print {
  .container {
    display: none;
  }
}