.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 15vh;
  max-height: 300px;
  margin-bottom: 3vh;
  padding: 1em;
  text-align: center;
  border: 1px solid var(--color-deep-purple);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    background-color: var(--color-apricot);
  }

  h3,
  p {
    color: var(--color-deep-purple);
    margin: 0;
  }

  h3 {
    font-size: 1.4em;
  }

  p {
    max-width: 80%;
  }

  .navLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    height: 100%;
    width: 100%;
  }

  .progressBarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;

    p {
      font-weight: 700;
      color: var(--color-deep-purple-transparent-1);
    }
  }

  .progressBar {
    display: flex;
    flex-direction: row;
    width: 70%;
    height: 12px;
    background-color: #f3f3f3;
    border: 0.5px solid var(--color-deep-purple);
    border-radius: 5px;
  }

  .progressBarFill {
    border-radius: 5px;
    height: 100%;
  }

  @media (max-width: 768px) {
    margin-bottom: 2vh;
    width: 100%;
  }
}