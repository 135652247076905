.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  border: solid var(--color-text-gray);
  border-radius: 2px;
  padding: 0.4em 0.4em;
  background-color: var(--color-text-gray);
  color: white;
  font-size: 1em;
  font-weight: 700;
  width: 15vw;
  max-width: 40em;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .container {
    flex-basis: 100%;
    width: 100%;
  }
}