.container {
  min-height: 93vh; // 94vh - 1vh for bottom border
  box-sizing: border-box;
  padding: 5vw;
  background-color: var(--color-white);

  hr {
    border: 3px solid var(--color-blue);
  }

  section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 3vh;
    gap: 2%;
  }

  @media (max-width: 768px) {
    section {
      flex-direction: column;
      width: 100%;
    }
  }
}