$color-blue: #1B75BB;
$color-purple: #6B2C90;
$color-pink: #EB008B;

.container {
  background: linear-gradient(45deg, darken($color-blue, 10%) 0%, darken($color-purple, 10%) 51.04%, darken($color-pink, 10%) 100%);
  
  main {
    min-height: 94vh; // 100vh - (header height 5vh + margin 1vh)
    width: 80vw;
    max-width: 1200px;
    margin: 1vh auto auto;
  }
  
  .mainLogin {
    margin: 0;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    background: linear-gradient(45deg, $color-blue 0%, $color-purple 51.04%, $color-pink 100%);
  }

  @media (max-width: 768px) {
    main {
      width: 90vw;
    }
  }
}

@media print {
  .container {
    main {
      max-width: 100%;
    }
  }
}