.container {
  display: flex;
  align-items: center;
  padding: 0 1vmax;
  height: 5vh;
  background-color: var(--color-deep-purple);

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
      padding: 0;
      margin: 0;
    }

    h3 {
      flex-grow: 1;
      background: var(--color-white);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      text-transform: uppercase;
      font-size: 1em;
      font-weight: 500;
      margin-left: 5px;
    }

    .techniusLogo {
      width: 1.4em;
      height: 1.4em;
    }

    .navLink {
      display: flex;
      align-items: center;
      column-gap: 5px;
      color: var(--color-white);
      text-decoration: none;

      &:hover {
        path {
          fill: var(--color-purple);
        }

        color: var(--color-purple);
      }
    }
  }

  .icons {
    display: flex;
    gap: 1.5em;
  }

  .iconLink {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: fill 0.5s;

    // &:hover {
    //   path {
    //     fill: var(--color-purple);
    //   }
    // }
  }
}

@media screen and (max-width: 768px) {
  .container {
    nav {
      h3 {
        display: none;
      }
    }

    .icons {
      span {
        display: none;
      }
    }

    nav .techniusLogo {
      width: 2em;
      height: 2em;
    }
  }
}

@media print {
  .container {
    display: none;
  }
}