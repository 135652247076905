.close {
  height: 3em;
  width: 3em;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1em;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1em;
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  }
}