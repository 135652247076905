.container {
  display: flex;
  flex-direction: row;
  color: var(--color-deep-purple);
  gap: 1em;

  svg {
    fill: var(--color-purple);
  }

  .current {
    font-weight: 600;
  }

  .next {
    display: flex;
    gap: 0.5em;
  }

  .navLink {
    display: flex;
    gap: 0.5em;
    color: var(--color-deep-purple);
  }

  .hideOnDesktop {
    display: none;
  }

}

@media screen and (max-width: 768px) {
  .container {
    gap: 0.3em;
    align-items: baseline;

    .current {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 25vw;
    }

    .prev a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 25vw;
    }

    .next {
      display: none;
    }

    .hideOnMobile {
      display: none;
    }

    .hideOnDesktop {
      display: inline-block;
    }
  }
}