@font-face {
  font-family: 'Azo Sans';
  font-weight: 900;
  font-style: normal;
  src: url('~assets/fonts/AzoSans-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 900;
  font-style: italic;
  src: url('~assets/fonts/AzoSans-BlackItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 700;
  font-style: normal;
  src: url('~assets/fonts/AzoSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 700;
  font-style: italic;
  src: url('~assets/fonts/AzoSans-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 400;
  font-style: italic;
  src: url('~assets/fonts/AzoSans-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 300;
  font-style: normal;
  src: url('~assets/fonts/AzoSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 300;
  font-style: italic;
  src: url('~assets/fonts/AzoSans-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 500;
  font-style: normal;
  src: url('~assets/fonts/AzoSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 500;
  font-style: italic;
  src: url('~assets/fonts/AzoSans-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 400;
  font-style: normal;
  src: url('~assets/fonts/AzoSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 100;
  font-style: normal;
  src: url('~assets/fonts/AzoSans-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 100;
  font-style: italic;
  src: url('~assets/fonts/AzoSans-ThinItalic.woff2') format('woff2');
}
