:root {
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-blue: #1B75BB;
  --color-blue-transparent: #1B75BB33;

  --color-dark-gray: #1E1E1E;
  --color-dark-gray-transparent-0: #E9E9E9;
  --color-dark-gray-transparent-1: #595959B2;
  --color-dark-gray-transparent-2: #292929B2;

  --color-purple: #6B2C90;
  --color-deep-purple: #0E0635;
  --color-deep-purple-transparent-1: #0E0635CC;
  --color-deep-purple-transparent-2: #0E063599;
  --color-deep-purple-transparent-3: #0E0635B2;
  --color-deep-purple-transparent-4: #0E063580;
  --color-deep-purple-transparent-5: #0E0635E5;
  --color-deep-purple-transparent-6: #0E063566;

  --color-gold: #E2AE27;
  --color-gold-transparent: #E2AE2780;

  --color-apricot: #F9ECC4;

  --color-light-pink: #EB008B;

  --color-text-gradient: linear-gradient(91.99deg, #EB008B 13.4%, #9D2C9D 48.25%, #1B75BB 81.68%);
  --color-main-bg-gradient: linear-gradient(45deg, #1B75BB 0%, #6B2C90 51.04%, #EB008B 100%);
  --color-bg-gradient: linear-gradient(180deg, #6B2C90 0%, #0E0635 43.75%);

  --color-gray: #716F6F;
  --color-light-gray: #737171;
  --color-text-gray: #5D5D5D;

  --color-green: #4CBC17;
  --color-light-green-transparent: #62AB1A80;

  --hr-border-style-1: 2px solid var(--color-dark-gray-transparent);
}