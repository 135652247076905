.container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container {
    flex-basis: 100%;
    width: 100%;
  }
}