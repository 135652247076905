.container {
  margin-top: 0.5em;
  textarea {
    padding: 1em;
    line-height: 1.5;
    width: 100%;
    border: 1px solid var(--color-deep-purple);
    box-sizing: border-box;
    resize: vertical;

    &:focus {
      outline: 1px solid var(--color-deep-purple);
    }
  }
}