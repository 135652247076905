.container {
  cursor: pointer;
  margin-top: 1em;

  span {
    margin-left: 0.25em;
    font-size: 1.2em;
    color: var(--color-deep-purple);
    font-weight: 500;
    text-decoration: underline;
  }

  .navLink {
    svg {
      fill: var(--color-deep-purple);
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    span {
      font-size: 1em;
    }

    svg {
      width: 1em;
      height: 1em;
    }
  }
}