.container {
  margin-top: -1em;
  margin-bottom: 0em;

  p {
    margin: 0;
    padding: 0;
    font-size: 1em;
    line-height: 150%;
    font-weight: 300;
    text-align: left;
    color: var(--color-deep-purple);
  }
}
