.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  .displayRows {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}