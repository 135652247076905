.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5vw;

  .home a {
    display: flex;
    align-items: center;
    gap: 0.25em;

    span {
      align-self: flex-end;
    }
  }

  .navLink {
    color: var(--color-deep-purple);
  }

  .iconLink {
    path {
      fill: var(--color-deep-purple);
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 3vh;

    .home {
      display: none;
    }
  }
}

@media print {
  .container {
    display: none;
  }
}