.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .draggableButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
  }

  .dropBoxes {
    display: flex;
    margin-top: 1em;
  }
}