.container {
  min-height: 93vh; // 94vh - 1vh for bottom border
  box-sizing: border-box;
  padding: 4vw;
  background-color: var(--color-white);


  hr {
    border: 2px solid var(--color-blue);
    width: 100%;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3vh;
    gap: 2%;
    justify-content: space-between;
    align-items: flex-start;

    article {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
      margin-top: 1em;

      div.onlyPrintView {
        display: none;
      }

      .techniusLogo {
        width: 1.4em;
        height: 1.4em;
      }

      .outputButtons {
        display: flex;
        color: var(--color-purple);
        cursor: pointer;

        button.report {
          display: flex;
          align-items: center;
          color: black;
          background-color: white;
          border: none;
          font-size: 1em;
          margin-left: 1.5em;
          padding: 0.5em 1em;
          cursor: pointer;
        }

        button.report:hover {
          background-color: #ccc;
        }

        .topBarIcon {
          margin-right: 0.5em;
          width: 1.5em;
          height: 1.5em;
        }
      }

      ul {
        margin-top: 0;

        &.lightFont {
          font-weight: 300;
        }

        &.skills {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          font-weight: 300;
          margin-top: 0;
          max-height: 10em;
          margin-bottom: 0;
        }

        &.techSkills {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          font-weight: 300;
          margin-top: 0.5em;
          max-height: 10em;
          margin-bottom: 0;
        }

        li {
          margin-bottom: 0.5em;

          &.techSkills {
            margin-right: 1.5em;
          }
        }
      }

      &.head {
        flex-direction: row;
        justify-content: space-between;
      }

      .userName {
        color: var(--color-deep-purple);
        color: #0E0635;
        font-size: 1.3em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: auto;
        margin-left: 5em;
        display: flex;
        align-self: center;
      }

      .preview {
        color: var(--color-light-pink);
        font-style: normal;
        font-weight: 600;
        font-size: 1.3em;
        line-height: normal;
        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    section {
      flex-direction: column;
      width: 100%;
    }
  }
}

@media print {
  .container {
    padding: 0;

    section {
      article {
        .userName {
          margin-left: 5em
        }

        .logo {
          max-height: 3em;
        }

        div.onlyPrintView {
          display: inline-flex;
          margin-right: 0.5em;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .noPrint {
    display: none;
  }

  .outputButtons {
    display: none !important;
  }
}