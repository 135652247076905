.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    min-height: 15vh;
    width: 100%;
    padding: 0.5em;
    margin-top: 0;
    gap: 1em;
    border: 1px solid var(--color-deep-purple-transparent-3);
    box-sizing: border-box;
  }
}