.container {
  input {
    width: 100%;
    padding: 0.8em 0.5em;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--color-deep-purple);
    background-color: var(--color-dark-gray-transparent-0);

    &:focus {
      outline: 1px solid var(--color-deep-purple);
    }
  }
}