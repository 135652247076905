.container {
  display: flex;
  position: relative;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 5vw 5vw 5vw;
  width: 100vw;

  .topRightGraphic {
    position: absolute;
    top: 25vh;
    right: 12vw;
    height: 30vh;
    width: 30vh;
    z-index: -1;
  }

  .bottomGraphic1 {
    position: absolute;
    bottom: 16vh;
    width: 100%;
    z-index: -1;
  }

  .bottomGraphic2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  h1 {
    margin-bottom: 2.5rem;
    color: white;
    font-weight: 500;
    font-size: 3em;
    letter-spacing: 0.04em;
  }

  form {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    width: 30vw;
    max-width: 500px;
    height: 30vh;
    background-color: white;
    border-radius: 0.25em;

    h4 {
      text-decoration: underline;
      align-self: flex-start;
      margin-left: 2em;
      margin-bottom: 2em;
    }

    input {
      margin-bottom: 1rem;
      padding: 0.5rem;
      width: 100%;
      box-sizing: border-box;
    }

    Button {
      padding: 1rem;
      height: auto;
      width: 90%;
      box-sizing: border-box;
      background-color: var(--color-purple);
      border: none;
    }
  }
}


@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    h1 {
      font-size: 2em;
      margin-bottom: 2.5rem;
      position: absolute;
      top: 10vh;
    }

    form {
      width: 90vw;
      height: 30vh;

      Button {
        height: auto;
        flex-basis: auto;
      }
    }

    .topRightGraphic {
      top: 14vh;
      right: 12vw;
      height: 15vh;
      width: 15vh;
    }

    .bottomGraphic1 {
      position: absolute;
      bottom: 10vh;
      z-index: -1;
      scale: 2;
    }

    .bottomGraphic2 {
      scale: 2;
    }
  }
}