.container {
  margin-top: 1em;
  padding: 0.5em 2em;
  background: var(--color-apricot);

  p {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 400;
    color: var(--color-deep-purple);
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 0.5em;
    padding: 0.8em;

    p {
      margin: 0;
    }
  }
}

@media print {
  .container {
    display: none;
  }
}