.container {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: inline-block;
    pointer-events: none;
    min-width: 8em;
    padding: 0.8em 1em;
    border: 3px solid var(--color-purple);
    background: var(--color-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  .container {
    button {
      line-height: 100%;
      padding: 0.5em 0.8em;
    }
  }
}