.container {
  margin-bottom: 1.5em;
  td {
    border-bottom: 1px solid #ccc;
  }

  th {
    padding-left: 1em;
    font-weight: normal;
  }

  td:nth-child(2) {
    padding-left: 3em;
  }
  td:nth-child(3) {
    padding-left: 4.5em;
  }
}