.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-size: 1.2em;
  }

  .navLink {
    color: var(--color-purple);
  }

  svg {
    fill: var(--color-purple);
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;

    p {
      margin: 0;
      font-size: 1em;
    }

    svg {
      width: 1em;
      height: 1em;
    }
  }
}