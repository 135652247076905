.container {
  min-height: 93vh; // 94vh - 1vh for bottom border
  box-sizing: border-box;
  padding: 5vw;
  background-color: var(--color-white);

  section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3vh;
    gap: 2%;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    section {
      flex-direction: column;
      width: 100%;
    }
  }
}