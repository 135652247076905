.container {
  h4 {
    display: flex;
    align-items: center;
    font-size: 1.25em;
    margin-bottom: 1em;
    color: var(--color-blue);
  }

  .editIcon {
    margin-left: 1em;
    width: 1em;
    height: 1em;
    cursor: pointer;
  }

  .editText {
    margin-left: 0.2em;
    margin-bottom: 0;
    margin-top: 0;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1em;
  }

  .navLink {
    display: flex;
    color: var(--color-blue);
  }
}

@media print {
  .editIcon {
    display: none;
  }

  .editText {
    display: none;
  }
}