.container {
  .choices {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 1em;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
      margin-bottom: 0;
      padding-left: 01em;
      li {
        margin-right: 2em;
        font-size: 1em;
        font-weight: 300;
        color: var(--color-deep-purple);
      }
    }

    button {
      display: flex;
      gap: 4px;
      font-size: 0.9em;
      font-weight: 500;
      border: 1px solid var(--color-gray);
      border-radius: 0.8em;
      color: var(--color-deep-purple);
      background-color: var(--color-white);
      padding: 4px 1em 4px;

      &.selected {
        color: var(--color-white);
        background-color: var(--color-blue);
      }
    }
  }
  .altInput {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
}