.container {
  margin-top: 1em;

  h4 {
    font-weight: 500;
    color: var(--color-deep-purple);
    margin-bottom: 1.2em;
  }
}

@media screen and (max-width: 768px) {
  .container {
    h4 {
      line-height: 150%;
    }
  }
}