.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: -1em;

  p {
    color: var(--color-text-gray);
    margin-bottom: 0;

    &.red {
      color: rgba(255, 0, 0, 0.822);
    }

    &.orange {
      color: rgb(218, 143, 4);
    }
  }
}