.container {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 94vh; // 100vh - (header height 5vh + margin 1vh)
    width: 80vw;
    max-width: 1400px;
    margin: auto auto;
  }
}