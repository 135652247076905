.container {
  input {
    width: 100%;
    padding: 0.8em 0.5em;
    margin-top: 0.5em;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid var(--color-text-gray);

    &:focus {
      outline: 1px solid var(--color-deep-purple);
    }
  }

  @media (max-width: 768px) {
    input {
      width: 100%;
    }
  }
}